import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import PageHeading from "~/components/styled/page-heading"
import CompanyList from "~/components/company-list"
import SEO from "~/components/seo"

const SearchPage = ({
  data: {
    allStrapiCompany: { edges },
  },
}) => {
  const flatCompanies = edges.map(({ node }) => node)
  const seo = { title: "公司" }

  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeading>公司</PageHeading>
      <CompanyList companies={flatCompanies} />
    </Layout>
  )
}

export const searchPageQuery = graphql`
  query CompanySearchQuery {
    allStrapiCompany {
      edges {
        node {
          id
          slug
          title
          description
          url
        }
      }
    }
  }
`

export default SearchPage
